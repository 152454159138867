import * as Sentry from '@sentry/gatsby'
import posthog from 'posthog-js'
export { wrapRootElement } from './src/utils/apollo/wrapRoot'

const initTracking = () => {
  posthog.init('phc_GRrdKfGvEXpAx48vqUAalBKzKPjtingsMhj0RfJMAtm', {
    api_host: 'https://t.formable.tools',
    autocapture: false,
  })

  Sentry.init({
    dsn: 'https://8ade7f330e9e380f01f10dad3e4b6727@o169800.ingest.sentry.io/4505647305785344',
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/formable\.tools\/app/,
        ],
      }),
      Sentry.replayIntegration(),
      posthog.sentryIntegration({
        organization: 'emil-bruckner',
        projectId: '4505647305785344',
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

if (process.env.NODE_ENV !== 'development') {
  initTracking()
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
