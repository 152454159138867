import { useNProgress } from '@tanem/react-nprogress'
import React, { useEffect, useRef, useState } from 'react'

export const GlobalLoadingIndicator = ({ useApolloNetworkStatus }: any) => {
  const status = useApolloNetworkStatus()

  const [isLoading, setIsLoading] = useState(status.numPendingQueries >= 1)
  const firstUpdate = useRef(true)

  // Only reset state when nothing is loading for 100ms
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined

    if (status.numPendingQueries === 0) {
      timer = setTimeout(() => setIsLoading(false), 100)
    } else {
      if (firstUpdate.current) {
        firstUpdate.current = false
      } else {
        setIsLoading(true)
      }

      if (timer) {
        clearTimeout(timer)
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [status])

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isLoading && !firstUpdate.current,
  })

  return (
    <div
      css={`
        opacity: ${isFinished ? 0 : 1};
        pointerevents: none;
        transition: opacity ${animationDuration}ms linear;
      `}
    >
      <div
        css={`
          background: #0012ef;
          height: 2px;
          left: 0;
          width: ${isFinished ? 0 : progress * 100}%;
          position: fixed;
          top: 0;
          transition: ${firstUpdate.current
            ? `none`
            : `width ${animationDuration}ms linear`};
          transition-delay: ${!isFinished ? 0 : animationDuration}ms;
          z-index: 1031;
        `}
      ></div>
    </div>
  )
}
