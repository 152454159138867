import gql from 'graphql-tag'

export const typeDefs = gql`
  type Modal {
    isOpen: Boolean!
    blockId: ID
    #   block: Boolean,
  }

  # type Block {
  #   uid: ID
  #   richText: String
  #   plainText: String
  # }

  extend type Query {
    modal: Modal
    blocksByIds(ids: [ID!]!): [Block!]!
  }

  extend type Block {
    clientId: String @client
  }

  extend type DateBlock {
    clientId: String @client
  }

  #   extend type Mutation {
  #     addOrRemoveFromCart(id: ID!): [ID!]!
  #   }
`

export const resolvers = {
  Modal: {
    // block() {
    //     return true
    // }
    // async block(modal, args, {client}, info) {
    //     console.log('resolver')
    //     const data = await client
    //         .query({
    //             query: gql`
    //                 {
    //                     blocks(parentIds: $parentIds) {
    //                         name
    //                         uid
    //                         relation {
    //                             uid,
    //                             name,
    //                         }
    //                     }
    //                 }
    //             `,
    //             variables: {
    //                 parentIds: ['0x1']
    //             }
    //         })
    //         console.log({data})
    //     return data
    //     return {
    //         __typename: 'Block',
    //     }
    // }
  },
}
