import '../../css/global.css'

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  gql,
  Observable,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { navigate } from 'gatsby'
import fetch from 'isomorphic-fetch'
import React from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'

import { GlobalLoadingIndicator } from '../../components/GlobalLoadingIndicator/GlobalLoadingIndicator'
import { cache } from '../cache'
import { resolvers, typeDefs } from '../resolvers'

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier()

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'UNAUTHENTICATED':
          navigate('/login/')
      }
    }
  }
})

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body)
  return fetch(`${uri}?opname=${operationName}`, options)
}

declare global {
  interface Window {
    recentlyDeletedBlocks: any
  }
}

if (typeof window !== 'undefined') {
  window.recentlyDeletedBlocks = window.recentlyDeletedBlocks ?? []
}

const cancelIfDeletedLink = new ApolloLink((operation, forward) => {
  if (operation.operationName === 'DeleteBlock') {
    window?.recentlyDeletedBlocks.push(operation.variables.id)
  }

  const {
    operationName,
    variables: { uid },
  } = operation

  if (operationName !== 'UpdateBlock') return forward(operation)

  // Could also not be in cache yet
  // const isInCache =
  //   cache.readFragment({
  //     id: `Block:${uid}`,
  //     fragment: gql`
  //       fragment BlockUid on Block {
  //         uid
  //       }
  //     `,
  //   }) ||
  //   cache.readFragment({
  //     id: `DateBlock:${uid}`,
  //     fragment: gql`
  //       fragment DateBlockUid on DateBlock {
  //         uid
  //       }
  //     `,
  //   })

  // if (!isInCache) {
  //   return new Observable((observer) => {
  //     observer.next({ data: { updateBlock: null } })
  //     observer.complete()
  //   })
  // }

  if (window?.recentlyDeletedBlocks.includes(uid)) {
    console.error('blocking update of deleted block')
    return new Observable((observer) => {
      observer.next({ data: { updateBlock: null } })
      observer.complete()
    })
  }

  return forward(operation)
})

const httpLink = from([
  cancelIfDeletedLink,
  link,
  createHttpLink({
    uri: `${process.env.API_URL}/graphql`,
    credentials: 'include',
    fetch: customFetch,
  }),
])

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache,
  typeDefs,
  resolvers,
})

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <GlobalLoadingIndicator useApolloNetworkStatus={useApolloNetworkStatus} />
      {element}
    </ApolloProvider>
  )
}
